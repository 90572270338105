import { axiosInstance as api } from "../helpers/request";
import { IReferral } from "../types/interfaces";

class AffiliateService {
  updateReferralKey({ key }: { key: string }) {
    return api.post<IReferral>("/auth/referral", { key });
  }

  getReferralInfo() {
    return api.get<IReferral>("/auth/referral");
  }
}

const affiliateServiceInstance = new AffiliateService();

export default affiliateServiceInstance;

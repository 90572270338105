export enum CouponTypeEnum {
  oneTime = "oneTime",
  referral = "referral",
  community = "community",
}

export enum StreamingPlatformEnum {
  youtube = "youtube",
  twitch = "twitch",
  facebook = "facebook",
  kick = "kick",
  custom = "custom",
}

export enum GenderEnum {
  male = "male",
  female = "female",
}

export enum VisualMediaTypeEnum {
  video = "video",
  image = "image",
  gif = "gif",
}
export enum VisualMediaClassificationEnum {
  playlistVideo = "playlistVideo",
  background = "background",
  overlay = "overlay",
}

export enum LiveStreamTypeEnum {
  playlist = "playlist",
  video = "video",
  forwarding = "forwarding",
}

export enum LiveStreamStatus {
  restarting = "restarting",
  initilizing = "initilizing",
  scheduled = "scheduled",
  preparingMedia = "preparingMedia",
  finalizing = "finalizing",
  compiling = "compiling",
  live = "live",
  pausing = "pausing",
  paused = "paused",
  resuming = "resuming",
  ending = "ending",
  ended = "ended",
}

export enum LiveStreamPlaybackOrderEnum {
  random = "random",
  sequential = "sequential",
}

export enum LiveStreamOrientationEnum {
  horizontal = "horizontal",
  vertical = "vertical",
}

export enum LiveStreamContentItemStatusEnum {
  queued = "queued",
  compiling = "compiling",
  ready = "ready",
}

export enum StreamingAccountSubscriptionStatus {
  unactive = "unactive",
  pendingPayment = "pendingPayment",
  processing = "processing",
  onHold = "onHold",
  paymentFailed = "paymentFailed",
  active = "active",
}

export enum OverlayTypeEnum {
  text = "text",
  song_title = "song_title",
  song_author = "song_author",
  image = "image",
  video = "video",
  gif = "gif",
}

export enum OverlayTextAlignEnum {
  left = "left",
  center = "center",
  right = "right",
}

export enum UserRoleEnum {
  user = "user",
  admin = "admin",
}

export enum PaymentVendorEnum {
  stripe = "stripe",
  paypal = "paypal",
}

export enum UserStatus {
  active = "active",
  suspended = "suspended",
  toBeDeleted = "toBeDeleted",
}

export enum NextLiveStreamContentItemStatusEnum {
  empty = "empty",
  loading = "loading",
  ready = "ready",
}

export enum LiveStreamStartOptionEnum {
  now = "now",
  schedule = "schedule",
}

export enum LiveStreamEndOptionEnum {
  never = "never",
  contentEnd = "contentEnd",
  schedule = "schedule",
}

export enum FolderTypeEnum {
  video = "video",
  audio = "audio",
}

export enum VisualMediaImportFromEnum {
  googleDrive = "googleDrive",
}

export enum LiveStreamJingleEveryTypeEnum {
  minutes = "minutes",
  songs = "songs",
}

export interface IIP {
  id: string;
  ip: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
}
export interface ISubscriptionPlanFeature {
  id: string;
  subscriptionPlanId: string;
  order: number;
  description: string;
  checked: boolean;
  createdAt: Date;
}

export interface ISubscriptionPlan {
  id: string;

  name: string;
  price: number;
  noOfPlatforms: number;
  storageInGB: number;
  active: boolean;
  popular: boolean;
  trial: boolean;

  subscriptionPlanFeatures: ISubscriptionPlanFeature[];
  paypalId?: string;

  updatedAt: Date;
  createdAt: Date;
}

export interface IStreamingAccountSubscriptionPlan {
  id: string;

  vendor: "paypal" | "stripe";

  active: boolean;
  subscriptionStatus: StreamingAccountSubscriptionStatus;
  paypalSubscriptionId?: string;
  isTrial: boolean;
  trialEndsAt?: string;
  trialEndNotificationSent: boolean;
  autoRenew: boolean;

  chargeAmount: number;
  noOfPlatforms: number;

  nextChargeAt?: string;
  lastChargeAt?: string;
  lastChargeAttemptAt?: string;
  failedChargeCount: number;
  agreementDate: string;

  subscriptionPlanId: string;
  subscriptionPlan: ISubscriptionPlan;

  createdAt: string;
}

export interface IStreamingWorker {
  id: string;
  status: string;
  restarts?: number;
  ready?: string;
  age?: string;
  ipv4?: string;
  restarting?: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface IStreamingAccount {
  id: string;
  name: string;

  userId: string;

  activeLiveStreamId?: string;

  streamingWorkerId?: string;
  streamingWorker?: IStreamingWorker;

  subscriptionId?: string;
  subscription?: IStreamingAccountSubscriptionPlan;

  deleted: boolean;
  deletedAt?: Date;

  createdAt: string;
  updatedAt: Date;
}
export interface IUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  country: string;
  defaultCardId: string;
  trialAvailable: boolean;
  role: "admin" | "user";
  verified: boolean;
  referralCommission: number;
  streamingAccounts: IStreamingAccount[];
  hasOldExperience: boolean;
  billingEmail?: string;
  companyName: string;
  postalCode: string;

  lastSeenAt?: string;
  liveStreams?: ILiveStream[];
  visualMediaSum?: number;
  audioSum?: number;
  latestIP?: string;
  registerIP?: string;
  userIPs?: IIP[];
  status?: UserStatus;
}

export interface IStreamingPlatform {
  id: string;
  name?: string;
  platform: StreamingPlatformEnum;
  streamKey?: string;
  streamUrl?: string;
  channelId?: string;
  activeLiveStreamId?: string;

  createdAt: Date | string;
  updatedAt: Date | string;
  videoKey?: string;
}

export interface ILiveStreamPlatforms {
  id: string;
  platform: StreamingPlatformEnum;

  viewers: number;
  likes: number;

  videoKey?: string;

  streamingPlatformId?: string;
  streamingPlatform?: IStreamingPlatform;

  createdAt: Date;
  updatedAt: Date;
}

export interface IAudio {
  id: string;
  name: string;
  author?: string | null;
  fileName: string;
  url: string;
  optimizedUrl?: string | null;
  optimizedUrlKey?: string | null;
  key: string;
  sizeInKB: number;
  duration: number;
  durationErrorCount: number;
  userId: string;
  folderId?: string;
  folder?: IFolder;
  createdAt: Date;
  updatedAt: Date;
}

export interface IVideoAudio {
  id: string;
  order: number;
  downloaded: boolean;
  videoId: string;
  audioId: string;
  audio: IAudio;
  createdAt: Date;
  updatedAt: Date;
}
export interface IVisualMedia {
  id: string;
  classification: VisualMediaClassificationEnum;
  type: VisualMediaTypeEnum;
  importLoading: boolean;
  importFrom?: VisualMediaImportFromEnum | null;
  name: string;
  author?: string | null;
  fileName: string;
  url: string;
  thubmnailUrl?: string | null;
  thumbnailSecond?: number | null;
  optimizedUrl?: string | null;
  optimizedUrlKey?: string | null;
  key: string;
  sizeInKB: number;
  duration: number;
  width?: number;
  height?: number;
  durationErrorCount: number;
  folderId?: string;
  folder?: IFolder;
  userId: string;
  streamingAccountId: string;
  streamingAccount: IStreamingAccount;
  createdAt: Date;
  updatedAt: Date;
}

export interface IFolder {
  id: string;
  isFolder: true;
  name: string;
  type: FolderTypeEnum;
  userId: string;
  visualMedias: IVisualMedia[];
  audios: IAudio[];
  createdAt: Date;
  updatedAt: Date;
  _count: { audios: number; visualMedias: number };
}

export interface IOverlay {
  id: string;
  type: OverlayTypeEnum;
  top?: number | null;
  left?: number | null;
  width?: number | null;
  height?: number | null;
  order: number;
  fontSize?: number | null;
  bold?: boolean | null;
  italic?: boolean | null;
  textDecoration?: string | null;
  fill?: string | null;
  text?: string | null;
  textAlign?: OverlayTextAlignEnum | null;
  videoId?: string | null;
  playlistId?: string | null;
  visualMediaId?: string | null;
  visualMedia?: IVisualMedia | null;
  createdAt: Date;
  updatedAt: Date;
  isSelected: boolean;
  url?: string | null;
}

export interface IVideoBackground {
  id: string;
  order: number;
  startSecond: number;
  endSecond: number;
  videoId: string;
  visualMediaId: string;
  visualMedia: IVisualMedia;
  createdAt: Date;
  updatedAt: Date;
}
export interface IPlaylistVisualMedia {
  id: string;
  order: number;
  downloaded: boolean;
  playlistId: string;
  visualMediaId: string;
  visualMedia: IVisualMedia;
  createdAt: Date;
  updatedAt: Date;
}

export interface IVideo {
  id: string;
  name: string;
  repeatVideo: boolean;
  keepLastFrame: boolean;
  fadeBetweenBackgrounds: boolean;
  duration: number;
  compiledVideoUrl?: string;
  changedAfterCompile: boolean;
  deleted: boolean;
  deletedAt?: Date;
  userId: string;
  audios: IVideoAudio[];
  backgrounds: IVideoBackground[];
  overlays: IOverlay[];
  createdAt: Date;
  updatedAt: Date;
}

export interface IPlaylist {
  id: string;
  name: string;
  duration: number;
  compiledVideoUrl?: string | null;
  changedAfterCompile: boolean;
  deleted: boolean;
  deletedAt?: Date | null;
  userId: string;
  streamingAccountId: string;
  overlays: IOverlay[];
  playlistVisualMedias: IPlaylistVisualMedia[];
  createdAt: Date;
  updatedAt: Date;
}

interface ILiveStreamContentItem {
  id: string;
  order: number;

  type: LiveStreamTypeEnum;

  compilingStartedAt?: Date;
  compiledDurationStr?: string;
  compiledDurationSeconds?: number;
  compiledVideoUrl?: string;

  status: LiveStreamContentItemStatusEnum;
  videoId?: string;
  video?: IVideo;

  playlistId?: string;
  playlist?: IPlaylist;

  createdAt: Date;
  updatedAt: Date;
}
export interface ILiveStream {
  id: string;
  type: LiveStreamTypeEnum;

  status: LiveStreamStatus;
  error: boolean;
  orientation: LiveStreamOrientationEnum;
  videoWidth: number;
  videoHeight: number;
  videoTop: number;
  videoLeft: number;
  videoBitrate: number;
  videoFPS: number;
  audioBitrate: number;
  crfMode: boolean;

  promoted: boolean;
  promotedUrl?: string;

  // scheduling options
  startOption: LiveStreamStartOptionEnum;
  endOption: LiveStreamEndOptionEnum;
  startDate?: Date;
  endDate?: Date;
  // playback settings
  playbackOrder: LiveStreamPlaybackOrderEnum;
  fadeBetweenTransitions: boolean;

  repeatOnEnd: boolean;
  contentDuration: number;
  compilingStartedAt?: Date;
  compiledVideoUploading?: boolean;
  compiledVideoUrl?: string;
  compiledDurationStr?: string;
  compiledDurationSeconds?: number;

  // Jingle settings
  jingleEvery: number;
  jingleEveryType: LiveStreamJingleEveryTypeEnum;
  jinglePlaybackOrder: LiveStreamPlaybackOrderEnum;

  restarts: number;
  lastRestartAt?: Date;

  termsAgreedAt?: Date;
  contentAgreedAt?: Date;

  activeItemId?: string;
  activeItemSeconds?: number;
  lastPingAt?: Date;
  activeBitrate?: string;
  activeSpeed?: string;

  activeShowId?: string;
  activeShowItemId?: string;
  activeShowItemSeconds?: number;

  workerVersion: number;

  viewers: number;
  likes: number;
  previewUrl?: string;

  lastAnalyticAt: Date;

  streamingAccountId: string;
  streamingAccount: IStreamingAccount;

  liveStreamPlatforms: ILiveStreamPlatforms[];
  activeStreamingAccount: IStreamingAccount[];
  activeLiveStreamContentItemId?: string;
  liveStreamContentItems: ILiveStreamContentItem[];
  liveStreamScheduledShows: ILiveStreamScheduledShow[];

  jinglePlaylist?: IPlaylist;
  jinglePlaylistId?: string;
  jingleVideo?: IVideo;
  jingleVideoId?: string;

  endedAt?: Date;
  createdAt: Date;
  updatedAt: Date;

  userId?: string;
  user?: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    lastSeenAt: string;
    country: string;
  };
  promotedAccepted?: boolean;
}

export interface ILiveStreamScheduledShow {
  id: string;
  name: string;
  startTime: Date;
  videoId?: string;
  video?: IVideo;
  playlistId?: string;
  playlist?: IPlaylist;
  switchMode: "wait" | "immediate";
}

export interface ISubscriptionCards {
  brand: string;
  createdAt: string;
  default: boolean;
  expiryMonth: number;
  expiryYear: number;
  id: string;
  last4: string;
}

export interface ISubscriptionCoupon {
  id: string;
  coupon: string;
  type: CouponTypeEnum;
  durationDays?: number;
}

export interface InvoicesDataProps {
  firstName?: string;
  lastName?: string;
  billingEmail?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  country?: string;
  email?: string;
  companyName?: string;
  postalCode?: string;
}

export interface IInvoice {
  id: string;
  amount: number;
  description: string;
  billToName: string;
  billToEmail: string;
  billToAddress1: string;
  billToAddress2: string;
  billToCity: string;
  billToState: string;
  billToCountry: string;
  billToPostalCode: string;
  billToCompany: string;
  stripeChargeId: string;
  stripeChargeStatus: string;
  subscriptionPlanId: string;
  userId: string;
  dateOfIssue: string;
  userCardId: string;
  createdAt: string;
}

export enum ReferralStatusEnum {
  pending = "pending",
  available = "available",
  paid = "paid",
}

export type ReferralType = {
  id: string;
  userReferralKeyId: string;
  referredUserId: string;
  status: ReferralStatusEnum;
  commission: number;
  pendingAt: string | null;
  availableAt: string | null;
  paidAt: string | null;
  createdAt: string;
  updatedAt: string;
  referredUser: IUser;
  userReferralKey: {
    user: IUser[];
  };
};

export interface ILiveStreamAnalytics {
  id: string;
  views: number;
  likes: number;
  createdAt: string;
}

export enum AnalyticsDateRangeEnum {
  today = "today",
  last7days = "last7days",
  last14days = "last14days",
}

export enum AnalyticsDataTypeEnum {
  likes = "likes",
  views = "views",
}

export type TrialIncidentsType = {
  id: string;
  trialConsumedHashId: string;
  userId: string;
  createdAt: string;
  user: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    country: string;
  };
  trialConsumedHash: {
    id: string;
    reason: string;
    userId: string;
  };
};

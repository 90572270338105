import { v4 as uuid } from "uuid";
import { IOverlay, OverlayTypeEnum } from "../../types/models";

export const CONTAINER_WIDTH = 600;
export const CONTAINER_HEIGHT = (CONTAINER_WIDTH / 16) * 9;
export const CONTAINER_DIAGONAL = Math.sqrt(
  CONTAINER_WIDTH * CONTAINER_WIDTH + CONTAINER_HEIGHT * CONTAINER_HEIGHT
);
export const IMAGE_PLACEHOLDER_URL = "/images/image_placeholder.jpeg";
export const GIF_PLACEHOLDER_URL = "/images/image_placeholder.jpeg";
export const VIDEO_PLACEHOLDER_URL = "/images/video_placeholder.png";
// export const CONTAINER_HEIGHT = 350;
// export const CONTAINER_WIDTH = CONTAINER_HEIGHT * 16 / 9;

export const COLOR_OPTIONS = [
  {
    value: "FFFFFF",
  },
  {
    value: "000000",
  },
  // {
  //   value: "TRANSPARENT",
  // },
  {
    value: "FF6900",
  },
  {
    value: "FCB900",
  },
  {
    value: "7BDCB5",
  },
  {
    value: "00D084",
  },
  {
    value: "00AFB9",
  },
  {
    value: "8ED1FC",
  },
  {
    value: "0693E3",
  },
  {
    value: "ABB8C3",
  },
  {
    value: "EB144C",
  },
  {
    value: "F78DA7",
  },
  {
    value: "9900EF",
  },
  {
    value: "9F86C0",
  },
];

export const OVERLAY_TYPE = {
  TEXT: OverlayTypeEnum.text,
  SONG_TITLE: OverlayTypeEnum.song_title,
  SONG_AUTHOR: OverlayTypeEnum.song_author,
  IMAGE: OverlayTypeEnum.image,
  VIDEO: OverlayTypeEnum.video,
  GIF: OverlayTypeEnum.gif,
};

export const OVERLAY_OPTIONS = [
  {
    title: "Text",
    // icon: MdShortText,
    type: OVERLAY_TYPE.TEXT,
    getTemplate: () => ({
      id: uuid(),
      type: "text",
      text: "Sample text",
      fontSize: 15,
      fill: "#ffffff",

      top: 0,
      left: 0,
    }),
  },
  {
    title: "Song Title",
    // icon: IoMdMusicalNotes,
    type: OVERLAY_TYPE.SONG_TITLE,
    getTemplate: () => ({
      id: uuid(),
      type: "song_title",
      text: "Song Title",
      fontSize: 15,
      fill: "#ffffff",
      top: 0,
      left: 0,
    }),
  },
  {
    title: "Song Author",
    // icon: BiPencil,
    type: OVERLAY_TYPE.SONG_TITLE,
    getTemplate: () => ({
      id: uuid(),
      type: "song_author",
      text: "Song Author",
      fontSize: 15,
      fill: "#ffffff",
      top: 0,
      left: 0,
    }),
  },
  {
    title: "Image",
    // icon: MdImage,
    type: OVERLAY_TYPE.IMAGE,
    getTemplate: () => ({
      id: uuid(),
      type: "image",
      top: 0,
      left: 0,
      width: 20,
      height: 20,
      url: "",
    }),
  },
  {
    title: "GIF",
    // icon: MdGif,
    type: OVERLAY_TYPE.GIF,
    getTemplate: () => ({
      id: uuid(),
      type: "gif",
      top: 0,
      left: 0,
      width: 20,
      height: 20,
      url: "",
    }),
  },
  {
    title: "Video",
    // icon: MdVideoLibrary,
    type: OVERLAY_TYPE.VIDEO,
    getTemplate: () => ({
      id: uuid(),
      type: "video",
      top: 0,
      left: 0,
      width: 20,
      height: 20,
      url: "",
    }),
  },
];

// define several math function
export function getCorner(
  pivotX: number,
  pivotY: number,
  diffX: number,
  diffY: number,
  angle: number
) {
  const distance = Math.sqrt(diffX * diffX + diffY * diffY);

  /// find angle from pivot to corner
  angle += Math.atan2(diffY, diffX);

  /// get new x and y and round it off to integer
  const x = pivotX + distance * Math.cos(angle);
  const y = pivotY + distance * Math.sin(angle);

  return { x: x, y: y };
}
export function getClientRect(rotatedBox: any) {
  const { x, y, width, height } = rotatedBox;
  const rad = rotatedBox.rotation;

  const p1 = getCorner(x, y, 0, 0, rad);
  const p2 = getCorner(x, y, width, 0, rad);
  const p3 = getCorner(x, y, width, height, rad);
  const p4 = getCorner(x, y, 0, height, rad);

  const minX = Math.min(p1.x, p2.x, p3.x, p4.x);
  const minY = Math.min(p1.y, p2.y, p3.y, p4.y);
  const maxX = Math.max(p1.x, p2.x, p3.x, p4.x);
  const maxY = Math.max(p1.y, p2.y, p3.y, p4.y);

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
}

export function getTotalBox(boxes: any) {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  boxes.forEach((box: any) => {
    minX = Math.min(minX, box.x);
    minY = Math.min(minY, box.y);
    maxX = Math.max(maxX, box.x + box.width);
    maxY = Math.max(maxY, box.y + box.height);
  });
  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
}

export function filterOverlays(overlays: IOverlay[]) {
  if (!overlays || !overlays.length) {
    return [];
  }

  return overlays.filter((overlay: any) => {
    if (
      ["gif", "image", "video"].includes(overlay.type) &&
      !overlay.visualMediaId
    ) {
      return false;
    }
    return true;
  });
}

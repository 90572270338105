import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISubscriptionCards } from "../../types/models";
import CardServicesInstance from "../../api/CardsService";
import { message } from "antd";
import { getMe } from "./AuthSlice";

interface initialState {
  cards: ISubscriptionCards[];
  loading: boolean;
  error: any;
  initAddCardLoading: boolean;
  initAddCardSecret: string;
  deleteCardLoading: boolean;
  markAsPrimaryLoading: boolean;
}

const initialState: initialState = {
  cards: [],
  loading: false,
  error: null,
  initAddCardLoading: false,
  initAddCardSecret: "",
  deleteCardLoading: false,
  markAsPrimaryLoading: false,
};

export const CardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setCardstoState(state, action: PayloadAction<ISubscriptionCards[]>) {
      state.cards = action.payload;
    },
    markCardAsPrimary(state, action: PayloadAction<ISubscriptionCards>) {
      const index = state.cards.findIndex(
        (item) => item.id === action.payload.id
      );
      const updatedElements = state.cards.splice(index, 1, action.payload);
      state.cards = [...state.cards];
    },
    setDeleteLoading(state, action: PayloadAction<boolean>) {
      state.deleteCardLoading = action.payload;
    },
    setDeleteCard(state, action: PayloadAction<ISubscriptionCards>) {
      state.cards = state.cards.filter((item) => item.id !== action.payload.id);
    },
    setMarkAsPrimaryLoading(state, action: PayloadAction<boolean>) {
      state.markAsPrimaryLoading = action.payload;
    },
    setInitAppLoading(state, action: PayloadAction<boolean>) {
      state.initAddCardLoading = action.payload;
    },
    setInitCard(state, action: PayloadAction<any>) {
      state.initAddCardSecret = action.payload.clientSecret;
    },
  },
});

export const {
  setLoading,
  setCardstoState,
  markCardAsPrimary,
  setDeleteLoading,
  setDeleteCard,
  setMarkAsPrimaryLoading,
  setInitAppLoading,
  setInitCard,
} = CardsSlice.actions;

export const getCards = createAsyncThunk(
  "getCards",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await CardServicesInstance.getCards();
      dispatch(setCardstoState(res.data));
    } catch (err: any) {
      message.error(err.message);
    }
    dispatch(setLoading(false));
  }
);

export const markCardAsPrimaryAction = createAsyncThunk(
  "markCardAsPrimary",
  async (id: string, { dispatch }) => {
    dispatch(setMarkAsPrimaryLoading(true));
    try {
      const res = await CardServicesInstance.markAsPrimary(id);
      dispatch(markCardAsPrimary(res.data));
      await dispatch(getMe());
      message.success("Marked As Primary Successfully");
    } catch (err: any) {
      message.error(err.message);
    }
    dispatch(setMarkAsPrimaryLoading(false));
  }
);

export const deleteCard = createAsyncThunk(
  "deleteCard",
  async (id: string, { dispatch }) => {
    dispatch(setDeleteLoading(true));
    try {
      const res = await CardServicesInstance.deleteCard(id);
      dispatch(setDeleteCard(res.data));
      message.success("Card Deleted Successfully");
    } catch (err: any) {
      message.error(err.message);
    }
    dispatch(setDeleteLoading(false));
  }
);

export const initAddCard = createAsyncThunk(
  "initAddCard",
  async (_, { dispatch }) => {
    dispatch(setInitAppLoading(true));
    try {
      const res = await CardServicesInstance.initAddCard();
      dispatch(setInitCard(res.data));
    } catch (err: any) {
      message.error(err.message || "Something went wrong");
    }
    dispatch(setInitAppLoading(false));
  }
);

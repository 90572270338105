import { axiosInstance as api } from "../helpers/request";
import { IVisualMedia, VisualMediaClassificationEnum } from "../types/models";

class VisualMediaService {
  getVisualMediaList({
    query = "",
    pageNumber,
    folderId,
    classification,
  }: {
    query?: string;
    pageNumber: number;
    folderId?: string;
    classification: VisualMediaClassificationEnum;
  }) {
    let url = `/visualMedia?classification=${classification}&search=${query}&page=${pageNumber}&perPage=10000`;
    if (folderId) {
      url += `&folderId=${folderId}`;
    }
    return api.get<{ data: IVisualMedia[] }>(url);
  }

  getVisualMediaById(id: string) {
    return api.get<IVisualMedia>(`/visualMedia/${id}`);
  }

  deleteVisualMedia(id: string) {
    return api.delete<IVisualMedia>(`/visualMedia/${id}`);
  }

  updateVisualMedia(data: Pick<IVisualMedia, "id"> | Partial<IVisualMedia>) {
    return api.patch<IVisualMedia>(`/visualMedia/${data.id}`, data);
  }

  duplicateVisualMedia(id: string) {
    return api.post<IVisualMedia>(`/visualMedia/duplicate/${id}`);
  }

  generatePresignedUrl({
    fileName,
    contentType,
  }: {
    fileName: string;
    contentType: string;
  }) {
    return api.post<{ url: string; Key: string }>(
      `/visualMedia/presigned/generate`,
      { fileName, contentType }
    );
  }
}

const VisualMediaInstance = new VisualMediaService();

export default VisualMediaInstance;

import { AuthSlice } from "./Slices/AuthSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  combineReducers,
  configureStore,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import { LayoutSlice } from "./Slices/LayoutSlice";
import { liveStreamSlice } from "./Slices/LiveStreamSlice";
import { folderSlice } from "./Slices/FolderSlice";
import { streamkeysSlice } from "./Slices/StreamKeysSlice";
import { mediaSlice } from "./Slices/MediaSlice";
import { AffiliateSlice } from "./Slices/AffiliateSlice";
import { SubscriptionSlice } from "./Slices/SubscriptionsSlice";
import { CardsSlice } from "./Slices/CardsSlice";
import { ShowsSlice } from "./Slices/ShowsSlice";
import { AdminSlice } from "./Slices/AdminSlice";

const rootReducer = combineReducers({
  auth: AuthSlice.reducer,
  admin: AdminSlice.reducer,
  layout: LayoutSlice.reducer,
  liveStream: liveStreamSlice.reducer,
  folder: folderSlice.reducer,
  streamKeys: streamkeysSlice.reducer,
  media: mediaSlice.reducer,
  subscription: SubscriptionSlice.reducer,
  referral: AffiliateSlice.reducer,
  card: CardsSlice.reducer,
  shows: ShowsSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  any,
  Action<string>
>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

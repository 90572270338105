import React, { Suspense } from "react";
import { RouteObject, createBrowserRouter } from "react-router-dom";
import DashboardLayout from "../Layouts/DashboardLayout/DashboardLayout";
import AdminLayout from "../Layouts/AdminLayout/AdminLayout";
import { ROUTES_MAP } from "./routesMap";
// // import LiveStreams from "../Pages/dashboard/live-streams/index.page";
// import AuthLayout from "../Layouts/AuthLayout/AuthLayout";
// import LoginPage from "../Pages/auth/login/login.page";
// import SignupPage from "../Pages/auth/signup/signup.page";
// import ForgotPasswordPage from "../Pages/auth/forgot-password/forgot-password.page";
// import ResetPasswordPage from "../Pages/auth/reset-password/reset-password.page";
// import NewLiveStreamPage from "../Pages/dashboard/live-streams/new.page";
// import StreamKeys from "../Pages/dashboard/StreamKeys";
// import Faqs from "../Pages/dashboard/Faqs/Faqs";
// import Affiliate from "../Pages/dashboard/Affiliate/Affiliate";
// import Subscriptions from "../Pages/Settings/Subscriptions/Subscriptions";
import { Join } from "../Pages/join/[id]/index.page";
import { LogoutPage } from "../Pages/dashboard/logout/index.page";
import { Flex, Spin } from "antd";

const LiveStreams = React.lazy(
  () => import("../Pages/dashboard/live-streams/index.page")
);
const AdminUsers = React.lazy(
  () => import("../Pages/dashboard/admin/users/users.page")
);
const AdminLiveStreams = React.lazy(
  () => import("../Pages/dashboard/admin/live-streams/live-streams.page")
);
const AdminReferrals = React.lazy(
  () => import("../Pages/dashboard/admin/referrals/referrals.page")
);
const AdminTrialIncidents = React.lazy(
  () => import("../Pages/dashboard/admin/trial-incidents/trial-incidents.page")
);
const AuthLayout = React.lazy(() => import("../Layouts/AuthLayout/AuthLayout"));
const LoginPage = React.lazy(() => import("../Pages/auth/login/login.page"));
const SignupPage = React.lazy(() => import("../Pages/auth/signup/signup.page"));
const ForgotPasswordPage = React.lazy(
  () => import("../Pages/auth/forgot-password/forgot-password.page")
);
const ResetPasswordPage = React.lazy(
  () => import("../Pages/auth/reset-password/reset-password.page")
);
const NewLiveStreamPage = React.lazy(
  () => import("../Pages/dashboard/live-streams/new.page")
);
const StreamKeys = React.lazy(() => import("../Pages/dashboard/StreamKeys"));
const Faqs = React.lazy(() => import("../Pages/dashboard/Faqs/Faqs"));
const Affiliate = React.lazy(
  () => import("../Pages/dashboard/Affiliate/Affiliate")
);
const Subscriptions = React.lazy(
  () => import("../Pages/Settings/Subscriptions/Subscriptions")
);
const LiveStreamDetails = React.lazy(
  () => import("../Pages/dashboard/live-streams/[id]/index.page")
);
const RedirectToHome = React.lazy(() => import("../Pages/redirect_to_home"));
const LoginAsUser = React.lazy(() => import("../Pages/login_as_user"));

const LoaderComponent = () => {
  return (
    <Flex justify="center" align="center">
      <Spin />
    </Flex>
  );
};

const routes: RouteObject[] = [
  // {
  //   path: ROUTES_MAP.landing,
  //   element: <Landing />,
  // },
  // {
  //   path: ROUTES_MAP.login,
  //   element: <Login />,
  // },
  // {
  //   path: ROUTES_MAP.resetPassword,
  //   element: <ResetPassword />,
  // },
  // {
  //   path: ROUTES_MAP.signUp,
  //   element: <SignUp />,
  // },
  {
    path: "/",
    element: (
      <Suspense fallback={<LoaderComponent />}>
        <RedirectToHome />
      </Suspense>
    ),
  },
  {
    path: ROUTES_MAP.join,
    element: (
      <Suspense fallback={<LoaderComponent />}>
        <Join />
      </Suspense>
    ),
  },
  {
    path: ROUTES_MAP.auth.asUser,
    element: (
      <Suspense fallback={<LoaderComponent />}>
        <LoginAsUser />
      </Suspense>
    ),
  },
  {
    path: ROUTES_MAP.auth.index,
    element: <AuthLayout />,
    children: [
      {
        path: ROUTES_MAP.auth.login,
        element: (
          <Suspense fallback={<LoaderComponent />}>
            <LoginPage />
          </Suspense>
        ),
      },
      {
        path: ROUTES_MAP.auth.signup,
        element: (
          <Suspense fallback={<LoaderComponent />}>
            <SignupPage />
          </Suspense>
        ),
      },
      {
        path: ROUTES_MAP.auth.forgotPassword,
        element: (
          <Suspense fallback={<LoaderComponent />}>
            <ForgotPasswordPage />
          </Suspense>
        ),
      },
      {
        path: ROUTES_MAP.auth.resetPassword,
        element: (
          <Suspense fallback={<LoaderComponent />}>
            <ResetPasswordPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: ROUTES_MAP.dashboard.index,
    element: (
      <Suspense fallback={<LoaderComponent />}>
        <DashboardLayout />
      </Suspense>
    ),
    children: [
      {
        path: ROUTES_MAP.dashboard.admin.index,
        element: (
          <Suspense fallback={<LoaderComponent />}>
            <AdminLayout />
          </Suspense>
        ),
        children: [
          {
            path: ROUTES_MAP.dashboard.admin.users,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AdminUsers />
              </Suspense>
            ),
          },
          {
            path: ROUTES_MAP.dashboard.admin.liveStreams,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AdminLiveStreams />
              </Suspense>
            ),
          },
          {
            path: ROUTES_MAP.dashboard.admin.referrals,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AdminReferrals />
              </Suspense>
            ),
          },
          {
            path: ROUTES_MAP.dashboard.admin.trialIncidents,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AdminTrialIncidents />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: ROUTES_MAP.dashboard.liveStreams,
        element: (
          <Suspense fallback={<LoaderComponent />}>
            <LiveStreams />
          </Suspense>
        ),
      },
      {
        path: ROUTES_MAP.dashboard.newLiveStream,
        element: (
          <Suspense fallback={<LoaderComponent />}>
            <NewLiveStreamPage />
          </Suspense>
        ),
      },
      {
        path: ROUTES_MAP.dashboard.liveStreamDetails(":id"),
        element: (
          <Suspense fallback={<LoaderComponent />}>
            <LiveStreamDetails />
          </Suspense>
        ),
      },
      {
        path: ROUTES_MAP.dashboard.streamKeys,
        element: (
          <Suspense fallback={<LoaderComponent />}>
            <StreamKeys />
          </Suspense>
        ),
      },
      {
        path: ROUTES_MAP.dashboard.faqs,
        element: (
          <Suspense fallback={<LoaderComponent />}>
            <Faqs />
          </Suspense>
        ),
      },
      {
        path: ROUTES_MAP.dashboard.affiliate,
        element: (
          <Suspense fallback={<LoaderComponent />}>
            <Affiliate />
          </Suspense>
        ),
      },
      {
        path: ROUTES_MAP.dashboard.settings.subscriptions,
        element: (
          <Suspense fallback={<LoaderComponent />}>
            <Subscriptions />
          </Suspense>
        ),
      },
      {
        path: ROUTES_MAP.dashboard.logout,
        element: (
          <Suspense fallback={<LoaderComponent />}>
            <LogoutPage />
          </Suspense>
        ),
      },
    ],
    // children: [
    //   {
    //     path: ROUTES_MAP.dashboard.videos,
    //     element: <Videos />,
    //   },
    //   {
    //     path: ROUTES_MAP.dashboard.pipelines,
    //     element: <Pipelines />,
    //   },
    //   {
    //     path: ROUTES_MAP.dashboard.connections,
    //     element: <Connections />,
    //   },
    //   {
    //     path: ROUTES_MAP.dashboard.settings.billing,
    //     element: <Billing />,
    //   },
    //   {
    //     path: ROUTES_MAP.dashboard.videoDetails(":id"),
    //     element: <VideoDetails />,
    //   },
    //   {
    //     path: ROUTES_MAP.dashboard.videoEdit(":id",":clipId"),
    //     element: <TextEditing />,
    //   },
    // ],
  },
];

export const router = createBrowserRouter(routes);

export const ROUTES_MAP = {
  landing: "/",
  join: "/join/:id",
  auth: {
    index: "/auth",
    login: "/auth/login",
    asUser: "/login/as-user",
    signup: "/auth/signup",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password",
  },
  dashboard: {
    index: "/dashboard",
    liveStreams: "/dashboard/live-streams",
    newLiveStream: "/dashboard/live-streams/new",
    liveStreamDetails: (id: string) => `/dashboard/live-streams/${id}`,
    affiliate: "/dashboard/affiliate",
    streamKeys: `/dashboard/stream-keys`,
    faqs: "/dashboard/faqs",
    logout: "/dashboard/logout",
    settings: {
      billing: `/dashboard/settings/billing`,
      profile: `/dashboard/settings/profile`,
      subscriptions: `/dashboard/settings/subscriptions`,
    },
    admin: {
      index: "/dashboard/admin",
      users: "/dashboard/admin/users",
      liveStreams: "/dashboard/admin/live-streams",
      trialIncidents: "/dashboard/admin/trial-incidents",
      referrals: "/dashboard/admin/referrals",
    },
  },
};

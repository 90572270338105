import Tracker from "@openreplay/tracker";

const tracker = new Tracker({
  projectKey: "nkW24mGASapzMs0vjcp3",
  ingestPoint: "https://or.streamingbots.com/ingest",
  __DISABLE_SECURE_MODE: process.env.NODE_ENV !== "production",
});

let userEmail = "";
export function initSessionTracker(cb: () => void = () => {}) {
  userEmail = "";
  tracker
    .start()
    .then(() => {
      console.log("Tracker started");
      cb();
    })
    .catch((err) => console.error(err));
}

export function identifySessionUser(email: string) {
  tracker.setUserID(email);
  userEmail = email;
}

import { Button, Flex, Typography } from "antd";
import { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

const RefreshErrorBoundary = ({ children }: { children: ReactNode }) => {
  return (
    <ErrorBoundary
      fallback={
        <Flex
          gap={10}
          style={{ alignItems: "center", width: "100%", height: "100%" }}
        >
          <Typography>
            Something went wrong. Please try refreshing the page
          </Typography>
          <Button
            type="primary"
            size="small"
            onClick={() => window.location.reload()}
          >
            Refresh
          </Button>
        </Flex>
      }
    >
      {children}
    </ErrorBoundary>
  );
};

export default RefreshErrorBoundary;

import React, { useEffect, useState } from "react";
import { Card, Layout, Tabs } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ROUTES_MAP } from "../../routes/routesMap";

import "./AdminLayout.scss";
import { useAppSelector } from "../../redux/store";

const AdminUsers = React.lazy(
  () => import("../../Pages/dashboard/admin/users/users.page")
);
const AdminLiveStreams = React.lazy(
  () => import("../../Pages/dashboard/admin/live-streams/live-streams.page")
);
const AdminReferrals = React.lazy(
  () => import("../../Pages/dashboard/admin/referrals/referrals.page")
);
const AdminTrialIncidents = React.lazy(
  () =>
    import("../../Pages/dashboard/admin/trial-incidents/trial-incidents.page")
);

const { Content } = Layout;

const items = [
  {
    key: ROUTES_MAP.dashboard.admin.liveStreams,
    label: "Live Streams",
    children: <AdminLiveStreams />,
  },
  {
    key: ROUTES_MAP.dashboard.admin.users,
    label: "Users",
    children: <AdminUsers />,
  },
  {
    key: ROUTES_MAP.dashboard.admin.referrals,
    label: "Referrals",
    children: <AdminReferrals />,
  },
  {
    key: ROUTES_MAP.dashboard.admin.trialIncidents,
    label: "Trial Incidents",
    children: <AdminTrialIncidents />,
  },
];

const HeaderTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState<string>(
    ROUTES_MAP.dashboard.admin.liveStreams
  );

  useEffect(() => {
    setActiveKey(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === ROUTES_MAP.dashboard.admin.index) {
      navigate(ROUTES_MAP.dashboard.admin.liveStreams);
    }
  }, []);

  const onChange = (key: string) => {
    navigate(key);
  };

  return (
    <Content>
      <Tabs
        activeKey={activeKey}
        items={items.map(({ key, label }) => ({ key, label }))}
        onChange={onChange}
      />
      <div className="tab-content">
        <Outlet />
      </div>
    </Content>
  );
};

const AdminLayout: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  if (!user?.role || user?.role !== "admin") {
    return null;
  }
  return (
    <Layout className="adminLayout">
      <Content>
        <HeaderTabs />
      </Content>
    </Layout>
  );
};

export default AdminLayout;

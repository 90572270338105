import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StreamKeysServiceInstance from "../../api/StreamKeysApi";
import { IStreamingPlatform } from "../../types/models";
import { IStreamingPlatformKey } from "../../types/interfaces";
import { message } from "antd";

interface initialState {
  streamingPlatforms: IStreamingPlatform[] | [];
  itemStream: IStreamingPlatform | {};
  loading: boolean;
  error: any;
}

const initialState: initialState = {
  streamingPlatforms: [],
  itemStream: {},
  loading: false,
  error: null,
};

export const streamkeysSlice = createSlice({
  name: "platform",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    getStreamingPlatforms(state, action: PayloadAction<IStreamingPlatform[]>) {
      state.streamingPlatforms = action.payload;
    },
    deleteStreamingPlatformKey(
      state,
      action: PayloadAction<IStreamingPlatform>
    ) {
      state.streamingPlatforms = state.streamingPlatforms.filter(
        (item) => item.id !== action.payload.id
      );
    },
    updateStreamingPlatformKey(
      state,
      action: PayloadAction<IStreamingPlatform>
    ) {
      const index = state.streamingPlatforms.findIndex(
        (item) => item.id === action.payload.id
      );

      const updatedElements = state.streamingPlatforms.splice(
        index,
        1,
        action.payload
      );
      state.streamingPlatforms = [...state.streamingPlatforms];
    },
    addStreamPlatformKey(state, action: PayloadAction<IStreamingPlatform>) {
      state.streamingPlatforms = [action.payload, ...state.streamingPlatforms];
    },
  },
});

export const {
  setLoading,
  getStreamingPlatforms,
  deleteStreamingPlatformKey,
  updateStreamingPlatformKey,
  addStreamPlatformKey,
} = streamkeysSlice.actions;

export const getStreamingPlatform = createAsyncThunk(
  "streamPlatform/getStreamingPlatforms",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await StreamKeysServiceInstance.getStreamPlatforms();
      dispatch(getStreamingPlatforms(res.data));
    } catch (err: any) {
      message.error(err.message);
    }
    dispatch(setLoading(false));
  }
);

export const deleteStreamingPlatform = createAsyncThunk(
  "streamPlatform/deleteStreamingPlatform",
  async (id: string, { dispatch }) => {
    setLoading(true);
    try {
      const res = await StreamKeysServiceInstance.deleteStreamPlatform(id);
      dispatch(deleteStreamingPlatformKey(res.data));
      message.success("Stream Key deleted successfully");
    } catch (err: any) {
      message.error(err.message);
    }
    setLoading(false);
  }
);

export const updateStreamPlatform = createAsyncThunk(
  "streamPlatform/updateStreamPlatform",
  async (platform: IStreamingPlatformKey, { dispatch }) => {
    setLoading(true);
    try {
      const res = await StreamKeysServiceInstance.updateStreamPlatform(
        platform
      );
      dispatch(updateStreamingPlatformKey(res.data));
      message.success("Stream Key updated successfully");
    } catch (err: any) {
      message.error(err.message);
    }
    setLoading(false);
  }
);

export const addStreamPlatform = createAsyncThunk(
  "streamPlatform/addStreamPlatform",
  async (platform: IStreamingPlatformKey, { dispatch }) => {
    setLoading(true);
    try {
      const res = await StreamKeysServiceInstance.addStreamPlatform(platform);
      dispatch(addStreamPlatformKey(res.data));
      message.success("Stream Key added successfully");
    } catch (err: any) {
      message.error(err.message);
    }
    setLoading(false);
  }
);

import { JINGLE_ITEM_PREFIX, SHOW_ITEM_PREFIX } from "../constants/constants";
import { IStreamingAccount, IStreamingPlatform } from "../types/models";

export const secondsToHHMM = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
};

export const secondsToHHMMSS = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);
  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const KBToMB = (kb: number) => {
  return parseFloat(`${kb / 1024}`).toFixed(2);
};

export const getFileDuration = (file: any) =>
  new Promise((resolve, reject) => {
    try {
      let aborted = false;
      try {
        console.log("Attempting to get duration", file);
        setTimeout(() => {
          console.log("Timed out", aborted);
          if (!aborted) {
            aborted = true;
            reject(new Error("Timed out getting duration"));
          }
        }, 60 * 1000);
        let video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          if (!aborted) {
            aborted = true;
            const duration = (this as HTMLVideoElement).duration;
            console.log("Metadata loaded", duration);
            resolve(duration);
          }
        };

        video.onerror = function (err) {
          console.log("On error", err);
          if (!aborted) {
            aborted = true;
            reject(new Error("Invalid video. Please select a video file."));
          }
        };

        video.src = URL.createObjectURL(file);
      } catch (e) {
        console.log(e);
        if (!aborted) {
          aborted = true;
          reject(e);
        }
      }
    } catch (err) {
      console.log(err);
    }
  });

// export const trackFacebookPurchase = () => {
//     try {
//         if (window.fbq) {
//             window.fbq("track", "Purchase", {
//                 value: 20,
//                 currency: "USD",
//             });
//         }
//     } catch (err) {
//         console.log(err);
//     }
// };

// export const trackRedditPurcahse = () => {
//     try {
//         if (window.rdt) {
//             window.rdt("track", "Purchase", {
//                 currency: "USD",
//                 itemCount: 1,
//                 value: 20,
//             });
//         }
//     } catch (err) {
//         console.log(err);
//     }
// };

// export const trackTiktokPurcahse = () => {
//     try {
//         if (window.ttq) {
//             window.ttq.track("CompletePayment", {
//                 quantity: 1,
//                 price: 20,
//                 value: 20,
//                 currency: "USD",
//             });
//         }
//     } catch (err) { }
// };

// export const trackQuoraPurcahse = () => {
//     try {
//         if (window.qp) {
//             window.qp('track', 'Purchase', { value: 29.99 });
//         }
//     } catch (err) { }
// };

// export const trackPurchase = () => {
//     if (process.env.NODE_ENV === "production") {
//         // trackRedditPurcahse();
//         // trackTiktokPurcahse();
//         // trackFacebookPurchase();
//         // trackQuoraPurcahse();
//     }
// };

// const trackRedditSignup = () => {
//     try {
//         if (window.rdt) {
//             window.rdt("track", "SignUp", {
//                 currency: "USD",
//                 value: 20,
//                 itemCount: 1,
//             });
//         }
//     } catch (err) {
//         console.log(err);
//     }
// };

// const trackFacebookSignup = () => {
//     try {
//         if (window.fbq) {
//             window.fbq("track", "CompleteRegistration");
//         }
//     } catch (err) {
//         console.log(err);
//     }
// };

// const trackTiktokSignup = () => {
//     try {
//         if (window.ttq) {
//             window.ttq.track("CompleteRegistration");
//         }
//     } catch (err) { }
// };

// const trackQuoraSignup = () => {
//     try {
//         if (window.qp) {
//             window.qp('track', 'Signup')
//         }

//     } catch (err) { }
// }

// export const trackSignup = () => {
//     if (process.env.NODE_ENV === "production") {
//         // trackRedditSignup();
//         // trackFacebookSignup();
//         // trackTiktokSignup();
//         // trackQuoraSignup();
//     }
// };

// export const updateUserCrispData = ({ email, firstName, lastName }) => {
//     if (typeof window !== 'undefined' && window.$crisp) {
//         try {
//             if (window.$crisp.get) {
//                 const visitorEmail = window.$crisp.get("user:email");
//                 if (!visitorEmail) {
//                     window.$crisp.push(["set", "user:email", email]);
//                     window.$crisp.push([
//                         "set",
//                         "user:nickname",
//                         `${firstName} ${lastName}`,
//                     ]);
//                 }
//             }
//         } catch (err) {
//         }
//     }
// };

export const reorderItems = (
  data: any[],
  startIndex: number,
  endIndex: number
) => {
  const newItemIds = data.slice();
  const [removed] = newItemIds.splice(startIndex, 1);
  newItemIds.splice(endIndex, 0, removed);
  return newItemIds.map((item, index) => ({ ...item, order: index + 1 }));
};

export const getLastOrder = (data: { order: number }[]) => {
  if (data.length === 0) {
    return 0;
  }
  return data.slice().sort((a, b) => b.order - a.order)[0]?.order || 0;
};

export const isAccountDisabled = (account: IStreamingAccount) => {
  return (
    !account?.subscription?.active ||
    account.activeLiveStreamId ||
    account?.streamingWorker?.status?.toLowerCase() === "provisioning"
  );
};

export const updateUserCrispData = ({
  email,
  firstName,
  lastName,
}: {
  email: string;
  firstName: string;
  lastName: string;
}) => {
  const anyWindow = window as any;
  if (typeof window !== "undefined" && anyWindow.$crisp) {
    try {
      if (anyWindow.$crisp.get) {
        const visitorEmail = anyWindow.$crisp.get("user:email");
        if (!visitorEmail) {
          anyWindow.$crisp.push(["set", "user:email", email]);
          anyWindow.$crisp.push([
            "set",
            "user:nickname",
            `${firstName} ${lastName}`,
          ]);
        }
      }
    } catch (err) {}
  }
};

export const getShowItemId = (showId: string, itemId: string) => {
  return `${SHOW_ITEM_PREFIX}${showId}_${itemId}_${Date.now()}`;
};

export const parseShowItemId = (itemId: string) => {
  const parts = itemId.replace(SHOW_ITEM_PREFIX, "").split("_");
  return {
    showId: parts[0],
    itemId: parts[1],
    date: parts[2],
  };
};

export const getJingleItemId = (itemId: string) => {
  return `${JINGLE_ITEM_PREFIX}${itemId}`;
};

export const parseJingleItemId = (itemId: string) => {
  return itemId.replace(JINGLE_ITEM_PREFIX, "");
};

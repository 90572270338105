import ReactDOM from "react-dom/client";
import "./index.scss";
import { BrowserRouter as Router, RouterProvider } from "react-router-dom";
import { ConfigProvider, notification, theme } from "antd";
import "./global.scss";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { router } from "./routes/routes";
import ThemeProvider from "./Themes/ThemeProvider";
import { BreakpointProvider } from "react-socks";
// import "./i18n";

notification.config({ placement: "topRight" });

const rootElement = document.getElementById("root")!;

const root = ReactDOM.createRoot(rootElement);
root.render(
  <Provider store={store}>
    <BreakpointProvider>
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </BreakpointProvider>
  </Provider>
);

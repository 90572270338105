import { axiosInstance as api } from "../helpers/request";
import {
  IActivateAccountBody,
  IPaypalsubscritpionAccountBody,
} from "../types/interfaces";
import {
  IInvoice,
  IStreamingAccount,
  ISubscriptionPlan,
} from "../types/models";

class SubscriptionService {
  getStreamingAccounts() {
    return api.get<IStreamingAccount[]>("/streaming-account");
  }

  createStreamingAccount(data: { name: string }) {
    return api.post<IStreamingAccount>("/streaming-account", data);
  }

  deActivateStreamingAccount(id: string) {
    return api.post(`/streaming-account/${id}/deactivate`);
  }

  reActivateStreamingAccount(id: string) {
    return api.post(`/streaming-account/${id}/reactivate`);
  }

  deleteStreamingAccount(id: string) {
    return api.delete(`/streaming-account/${id}`);
  }

  getSubscriptionPlans() {
    return api.get<ISubscriptionPlan[]>("/subscription-plan");
  }

  activateStreamingAccountPaypal(data: IPaypalsubscritpionAccountBody) {
    return api.post(`/streaming-account/${data.id}/activate/paypal`, data);
  }

  activateStreamingAccount(data: IActivateAccountBody) {
    return api.post<IStreamingAccount>(
      `/streaming-account/${data.id}/activate`,
      data
    );
  }

  applyCoupon(coupon: string) {
    return api.post("/streaming-account/coupon", { coupon });
  }

  getStreamAccountInvoices() {
    return api.get<IInvoice[]>(`/streaming-account/invoices`);
  }
}

const SubscriptionServiceInstance = new SubscriptionService();

export default SubscriptionServiceInstance;

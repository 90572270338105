import {
  IAudio,
  IOverlay,
  LiveStreamEndOptionEnum,
  LiveStreamJingleEveryTypeEnum,
  LiveStreamOrientationEnum,
  LiveStreamPlaybackOrderEnum,
  LiveStreamStartOptionEnum,
  LiveStreamTypeEnum,
} from "./models";

export interface IStreamingPlatformKey {
  id: string;
  platform: string;
  name: string;
  streamKey?: string;
  streamUrl?: string;
  channelId?: string;
}
export interface IPendingUploadItem {
  id: string;
  body: File;
  uploaded: boolean;
  failed?: boolean;
  progress: number;
  folderId?: string;
}
export interface IStreamSelectedItem {
  id: string;
  name: string;
  author?: string | null;
  duration: number;
  width?: number;
  height?: number;
  sizeInKB: number;
  type: "audio" | "video";
  url?: string;
  thubmnailUrl?: string | null;
  order: number;
  isChecked?: boolean;
}

export interface IReferral {
  key: {
    createdAt: string;
    friendlyKey: string;
    id: string;
    key: string;
    updatedAt: string;
  } | null;
  invited: number;
  pending: number;
  paid: number;
  available: number;
  referralInfoLoading: boolean;
  referralInfoError: any;
}
export interface IPaypalsubscritpion {
  subscriptionID: string;
}

export interface IPaypalsubscritpionAccountBody {
  id: string;
  paypalSubscriptionId: string;
  subscriptionPlanId: string;
}

export interface IActivateAccountBody {
  id: string;
  agreementDate?: string | null;
  cardId: string | null;
  subscriptionPlanId: string;
  coupon: any;
}
export interface ICreateLiveStreamRequest {
  playbackOrder: LiveStreamPlaybackOrderEnum;
  startDate?: Date;
  endDate?: Date;
  startOption: LiveStreamStartOptionEnum;
  endOption: LiveStreamEndOptionEnum;
  streamingAccountId: string;
  videoId?: string;
  playlistId?: string;
  repeatOnEnd: true;
  streamingPlatformsIds: string[];
  termsAgreedAt: Date;
  contentAgreedAt: Date;
  videoWidth: number;
  videoHeight: number;
  videoLeft: number;
  videoTop: number;
  videoBitrate: number;
  videoFPS: number;
  audioBitrate: number;
  orientation: LiveStreamOrientationEnum;
  liveStreamContentItems: {
    order: number;
    type: LiveStreamTypeEnum;
    videoId?: string | null;
    playlistId?: string | null;
  }[];
  liveStreamScheduledShows: ILiveStreamScheduledShow[];
  jingleEvery: number;
  jingleEveryType: LiveStreamJingleEveryTypeEnum;
  jinglePlaybackOrder: LiveStreamPlaybackOrderEnum;
  jinglePlaylistId?: string;
  jingleVideoId?: string;
  crfMode: boolean;
}

export interface ILiveStreamScheduledShow {
  name: string;
  startTime: Date;
  playlistId?: string;
  videoId?: string;
  switchMode: "wait" | "immediate";
}

export interface IUpdateLiveStreamRequest extends ICreateLiveStreamRequest {
  id: string;
}

export interface ICreateVideoRequest {
  name: string;
  export: boolean;
  fadeBetweenBackgrounds: boolean;
  backgrounds: {
    visualMediaId: string;
    order: number;
    startSecond: number;
    endSecond: number;
  }[];
  audios: { audioId: string; order: number }[];
  overlays: Omit<
    IOverlay,
    "id" | "visualMedia" | "url" | "isSelected" | "videoId" | "playlistId"
  >[];
}

export interface IUpdateVideoRequest extends ICreateVideoRequest {
  id: string;
}

export interface ICreatePlaylistRequest {
  name: string;
  playlistVisualMedias: { visualMediaId: string; order: number }[];
  overlays: Omit<
    IOverlay,
    "id" | "visualMedia" | "url" | "isSelected" | "videoId" | "playlistId"
  >[];
}

export interface IUpdatePlaylistRequest extends ICreatePlaylistRequest {
  id: string;
}

export interface IActiveStreamingAccountRequest {
  id: string;
  agreementDate: Date;
  cardId: string;
  subscriptionPlanId: string;
  couponId: string;
}

export interface IActivateStreamingAccountPaypalRequest {
  id: string;
  paypalSubscriptionId: string;
  subscritionPlanId: string;
}

export interface IOverlaySelectable extends IOverlay {
  isSelected: boolean;
}

export interface IScheduledShow {
  id: string;
  name: string;
  startTime: Date;
  items: IStreamSelectedItem[];
  backgroundId?: string;
  playlistId?: string;
  videoId?: string;
  switchMode: "wait" | "immediate";
}

export enum IStreamPlaylistType {
  primary = "primary",
  scheduled = "scheduled",
  jingle = "jingle",
}

import { ConfigProvider, theme } from "antd";
import { ReactNode, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { setThemeColors } from "../redux/Slices/LayoutSlice";

interface IThemeProviderProps {
  children: ReactNode | ReactNode[];
}

const colors = {
  dark: {
    colorPrimary: "#6656ED",
    textPrimary: "white",
    textPrimaryInverted: "black",
    textSecondary: "#9CA3AF",
    bgMain: "#06001C",
    bgSecondary: "#0F0A2E",
    bgGradientMain:
      "linear-gradient(rgba(102, 86, 237, 0.1), rgba(102, 86, 237, 0))",
  },
  light: {
    colorPrimary: "#6656ED",
    textPrimary: "black",
    textPrimaryInverted: "white",
    textSecondary: "#9CA3AF",
    bgMain: "white",
    bgSecondary: "#eeeeee",
    bgGradientMain:
      "linear-gradient(rgba(102, 86, 237, 0.1), rgba(102, 86, 237, 0))",
  },
};

const ThemeProvider = ({ children }: IThemeProviderProps) => {
  const { themeMode } = useAppSelector((state) => state.layout);
  const themeColors = themeMode == "dark" ? colors.dark : colors.light;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setThemeColors(themeColors));
  }, [themeColors]);

  return (
    <ConfigProvider
      theme={{
        algorithm: [
          // theme.compactAlgorithm,
          themeMode === "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm,
        ],
        token: {
          colorPrimary: themeColors.colorPrimary,
          colorBgBase: themeColors.bgMain,
          colorBgContainer: themeColors.bgSecondary,
          colorBorderSecondary: "rgba(255, 255, 255, 0.1)",
        },
        components: {
          Input: {
            colorBgBase: "white",
            colorBgContainer: "white",
            // colorText: "#6B7280",
            colorText: "black",
            colorTextPlaceholder: "#6B7280",
            colorTextDisabled: "#6B7280",
            colorBgContainerDisabled: "white",
          },
          Dropdown: {},
          Select: {
            colorBgBase: "white",
            colorBgContainer: "white",
            colorText: "black",
            optionSelectedBg: "white",
            optionActiveBg: "white",
            selectorBg: "white",
            colorBgLayout: "white",
            colorBgElevated: "white",
            colorIcon: "#000000",
            colorIconHover: "black",
            optionSelectedColor: "black",
            colorBgContainerDisabled: "#eee",
            colorTextDisabled: "#6B7280",
          },
          Modal: {
            titleFontSize: 18,
            titleLineHeight: 3,
          },
          Button: {
            borderColorDisabled: "transparent",
            colorBorder: "white",
            colorLink: themeColors.colorPrimary,
          },
          Checkbox: {
            colorBorder:
              themeMode === "dark" ? "white" : themeColors.colorPrimary,
          },
          Radio: {
            colorBorder:
              themeMode === "dark" ? "white" : themeColors.colorPrimary,
          },
          Layout: {
            headerBg: themeColors.bgMain,
            siderBg: themeColors.bgSecondary,
          },
          Typography: {
            fontSize: 16,
            colorText: themeColors.textSecondary,
            colorLink: themeColors.colorPrimary,
            linkDecoration: "underline",
          },
          Card: {
            padding: 40,
            borderRadiusOuter: 20,
            borderRadius: 20,
            headerHeight: 67,
          },
          Menu: {
            colorBgBase: themeColors.bgSecondary,
            itemBg: themeColors.bgSecondary,
            itemSelectedBg: themeColors.colorPrimary,
            itemSelectedColor: themeColors.textPrimary,
            itemMarginBlock: 20,
            itemMarginInline: 20,
            itemPaddingInline: 20,
            fontSize: 16,
          },
          Collapse: {
            colorBorder: "rgba(255, 255, 255, 0.1)",
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default ThemeProvider;
